import "@styles/app.css";
import "@styles/globals.css";
import theme from "chakra.theme";
import "intersection-observer";

import clsx from "clsx";
import { Provider as JotaiProvider } from "jotai";
import { NextComponentType, NextPageContext } from "next";
import Head from "next/head";
import { useRouter } from "next/router";

import NProgress from "nprogress";
import React, { useEffect } from "react";

import { ChakraProvider } from "@chakra-ui/react";

import Layout from "@layouts/Layout";
import modifyUtms from "@sharedLib/modifyUtms";
import cookieCutter from "cookie-cutter";
import Script from "next/script";

import { handleResize } from "@lib/handleResize";
import SiteFooter from "@modules/SiteFooter";
import SiteHeader from "@modules/SiteHeader";
import SiteMenu from "@modules/SiteMenu";
import SiteMobileMenu from "@modules/SiteMobileMenu";

NProgress.configure({ easing: "easeOut", speed: 750 });

declare global {
  interface Window {
    dataLayer: any;
    ga?: any;
    rudderanalytics?: any;
    _zi_fc?: any;
  }
}

type AppProps = {
  pageProps: any;
  err: any;
  Component: NextComponentType<NextPageContext, any, {}> & { Layout: any };
};

function MyApp({ Component, pageProps, err }: AppProps): JSX.Element {
  const TargetLayout = Component.Layout ? Component.Layout : Layout;

  const router = useRouter();

  /* Cookie Handling */
  useEffect(() => {
    /* Get new URL parameters (including UTMs) */
    const newUrlParams = router.query;

    /* If there are new URL parameters, process and then store them in cookies */
    if (Object.keys(newUrlParams).length) {
      /* Set cookie expiration dates */
      const expireDateShort = new Date(); /* Short expiration, used for non-UTM params */
      expireDateShort.setDate(expireDateShort.getDate() + 1);
      const expireDateLong = new Date(); /* Long expiration, used for UTMs */
      expireDateLong.setDate(expireDateLong.getDate() + 60);

      /* Get currently-stored URL and UTM params */
      const storedUrlParams = cookieCutter.get("urlParams") ? JSON.parse(cookieCutter.get("urlParams")) : {};
      const storedUtms = cookieCutter.get("utmParams") ? JSON.parse(cookieCutter.get("utmParams")) : {};
      
      /* Check if newUrlParams contains UTMs */
      let utms = ["utm_medium", "utm_source", "utm_campaign", "utm_content", "utm_term"];
      let hasNewUtms = utms.some((key) => {
        return key in newUrlParams;
      });
      /* If no UTMs are present in the URL, check if we can dynamically generate them */
      if (!hasNewUtms && document?.referrer) {
        /* Check if the referring URL SLD matches criteria to dynamically generate new UTMs */
        /* Currently this is just for search engines, but could be expanded to include other sources */
        const searchEngines = ["google", "bing", "yahoo", "duckduckgo", "baidu", "yandex", "naver"];
        const referrerHostname = new URL(document.referrer).hostname;
        const referrerHostnameParts = referrerHostname.split(".");
        // const referrerHostnameTLD = referrerHostnameParts[referrerHostnameParts.length - 1]; /* e.g. "com" */
        const referrerHostnameSLD = referrerHostnameParts[referrerHostnameParts.length - 2]; /* e.g. "google" */
        if (searchEngines.includes(referrerHostnameSLD)) {
          newUrlParams["utm_medium"] = "Organic Website";
          newUrlParams["utm_source"] = referrerHostnameSLD; /* Will be standardized via the modifyUtms function */
          hasNewUtms = true;
        }
      }
      /* If UTMs are present in the URL or we dynamically generated them... */
      const newUtms = {};
      if (hasNewUtms) {
        /* Clear all UTMs from storedUtms (and storedUrlParams for backwards compatibility) */
        utms.forEach((key) => {
          delete storedUtms[key];
          delete storedUrlParams[key];
        });
        /* Add the utm_datetime (set to current timestamp) to the newUtms */
        newUtms["utm_datetime__c"] = new Date().toISOString();
      }
      /* Add the "extended UTM params" (gclid, etc.) to list of items to store in newUtms (does not require hasNewUtms to be true) */
      utms = [...utms, "gclid", "GCLID__c", "msclkid", "fbclid"];
      /* Strip the UTMs out of newUrlParams and store in newUtms */
      utms.forEach((key) => {
        if (key in newUrlParams) {
          newUtms[key] = newUrlParams[key];
          delete newUrlParams[key];
        }
      });

      /* Build new cookie objects */
      const urlParamsCookie = {
        ...storedUrlParams,
        ...newUrlParams
      };
      const utmParamsCookie = {
        ...storedUtms,
        ...newUtms,
      };

      /* Store new cookies */
      if (Object.keys(urlParamsCookie).length) {

        /* Store URL params */
        cookieCutter.set("urlParams", JSON.stringify(urlParamsCookie), {
          expires: expireDateShort, // Non-UTM params stored for 1 day
        });
      }
      if (Object.keys(utmParamsCookie).length) {
        /* Modify UTMs to match Salesforce field names */
        const modifiedUtms = modifyUtms(utmParamsCookie);

        /* Store modified UTMs */
        cookieCutter.set("utmParams", JSON.stringify(modifiedUtms), {
          expires: expireDateLong, // UTM params stored for 60 days
        });
      }
    }
  }, [router.query]);

  /* Page Navigation */
  useEffect(() => {
    /* Progress bar for page navigation */
    const routeChangeStart = () => {
      NProgress.start();
    };

    const routeChangeEnd = () => {
      NProgress.done();
    };

    addEventListener("resize", () => {
      handleResize();
    });

    router.events.on("routeChangeStart", routeChangeStart);
    router.events.on("routeChangeComplete", routeChangeEnd);
    router.events.on("routeChangeError", routeChangeEnd);

    /* Google Tag Manager page view events */
    const handleRouteChange = (url) => {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'page_view',
        page: url,
      })
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events]);

  useEffect(() => {
    handleResize();
  });

  /*
    Init tag manager
  */

  return (
    <JotaiProvider>
      <Head>
        <meta charSet="utf-8" />
        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta
          name="viewport"
          content="height=device-height, width=device-width, initial-scale=1.0, minimum-scale=1.0"
        />
        <meta name="format-detection" content="telephone=no" />

        <link rel="preconnect" href="https://cmp.osano.com" />

        <meta charSet="utf-8" />

        <link
          rel="sitemap"
          type="application/xml"
          title="Sitemap"
          href="/sitemaps-1-sitemap.xml"
        />

        {/* Favicon */}
        <link rel="apple-touch-icon-precomposed" sizes="57x57" href="/apple-touch-icon-57x57.png" />
        <link rel="apple-touch-icon-precomposed" sizes="114x114" href="/apple-touch-icon-114x114.png" />
        <link rel="apple-touch-icon-precomposed" sizes="72x72" href="/apple-touch-icon-72x72.png" />
        <link rel="apple-touch-icon-precomposed" sizes="144x144" href="/apple-touch-icon-144x144.png" />
        <link rel="apple-touch-icon-precomposed" sizes="60x60" href="/apple-touch-icon-60x60.png" />
        <link rel="apple-touch-icon-precomposed" sizes="120x120" href="/apple-touch-icon-120x120.png" />
        <link rel="apple-touch-icon-precomposed" sizes="76x76" href="/apple-touch-icon-76x76.png" />
        <link rel="apple-touch-icon-precomposed" sizes="152x152" href="/apple-touch-icon-152x152.png" />
        
        <link rel="icon" type="image/png" sizes="196x196" href="/favicon-196x196.png" />
        <link rel="icon" type="image/png" sizes="96x96" href="/favicon-96x96.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="icon" type="image/png" sizes="128x128" href="/favicon-128.png" />

        <meta name="application-name" content="&nbsp;" />
        <meta name="msapplication-TileColor" content="#FFFFFF" />
        <meta name="msapplication-TileImage" content="/mstile-144x144.png" />
        <meta name="msapplication-square70x70logo" content="/mstile-70x70.png" />
        <meta name="msapplication-square150x150logo" content="/mstile-150x150.png" />
        <meta name="msapplication-wide310x150logo" content="/mstile-310x150.png" />
        <meta name="msapplication-square310x310logo" content="/mstile-310x310.png" />

        <meta name="apple-mobile-web-app-title" content="Abnormal Security" />
        <meta name="application-name" content="Abnormal" />
        <meta name="apple-mobile-web-app-status-bar-style" content="white" />
        <meta name="format-detection" content="telephone=no" />
      </Head>

      {(process.env.NEXT_PUBLIC_GTM_ID) && (
        <>
          {/* Consent Managers (Google Consent Manager 2.0, Osano) are located in /_document.tsx */}
          
          {/* Google Tag Manager */}
          <Script id="gtm" strategy="afterInteractive">
            {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');`}
          </Script>
        </>
      )}

      <ChakraProvider theme={theme}>
        <div className="flex flex-col justify-between min-h-screen">
          <SiteHeader
            navigationItems={pageProps.navigation?.primaryNavigation}
            callToActions={pageProps.navigation?.primaryCallToActions}
            globalSet={pageProps.globalSet}
          />

          <div
            className={clsx(
              "SiteHeader__siteMenu",
              "fixed inset-x-0 top-0 z-40 hidden lg:block"
            )}
          >
            <SiteMenu items={pageProps.navigation?.primaryNavigation} />
          </div>

          <TargetLayout {...pageProps}>
            <Component {...pageProps} err={err} />
          </TargetLayout>

          <SiteFooter
            primaryNavigation={pageProps.navigation?.footerPrimaryNavigation}
            secondaryNavigation={pageProps.navigation?.footerSecondaryNavigation}
            globalSet={pageProps.globalSet}
          />
        </div>
        <div className={clsx("block xl:hidden")}>
          <SiteMobileMenu
            items={pageProps.navigation?.primaryNavigation}
            callToActions={pageProps.navigation?.primaryCallToActions}
            globalSet={pageProps.globalSet}
          />
        </div>
      </ChakraProvider>
    </JotaiProvider>
  );
}
export default MyApp;
