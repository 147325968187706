import clsx from "clsx";

import DotBorder from "@components/DotBorder";

const HeaderBuffer = ({ hideHeaderBuffer = false }) => {
	return (
		<>
			<div className="header-buffer relative">
				{!hideHeaderBuffer && <DotBorder bottomOnly={true} />}
			</div>
		</>
	);
};

export default HeaderBuffer;
