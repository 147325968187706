const getImageBlurOptions = (image, imageBlur) => {
  let imageBlurOptions = {};

  if (!!image && image.extension !== "svg" && !!imageBlur) {
    imageBlurOptions = {
      placeholder: "blur",
      blurDataURL: imageBlur.url,
    };
  }

  return imageBlurOptions;
};

export default getImageBlurOptions;
