import { ReactNode } from "react";
import clsx from "clsx";

import { NavigationItem } from "@interfaces/NavigationItem.interface";
import HeaderBuffer from "@components/HeaderBuffer";

interface IProps {
  navigation: {
    primaryNavigation: NavigationItem[];
    primaryCallToActions: NavigationItem[];
    footerPrimaryNavigation: NavigationItem[];
    footerSecondaryNavigation: NavigationItem[];
  };
  children: ReactNode;
  backgroundColor;
  hideHeaderBuffer;
}

const Layout = ({
  children,
  backgroundColor,
  hideHeaderBuffer = false,
}: IProps): JSX.Element => {
  return (
    <>
      <main id="content" className={clsx("mb-auto", backgroundColor)}>
        <HeaderBuffer hideHeaderBuffer={hideHeaderBuffer} />
        <div className="bg-grid">{children}</div>
      </main>
    </>
  );
};

export default Layout;
