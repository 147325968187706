import clsx from "clsx";

const DotBorder = ({ bottomOnly = false, topOnly = false }) => {
	return (
		<>
			{!bottomOnly && !topOnly && (
				<>
					<div className="xl:dot-border left"></div>
					<div className="xl:dot-border right"></div>
					<div className="xl:dot-border top"></div>
					<div className="xl:dot-border bottom"></div>
				</>
			)}
			{bottomOnly && (
				<div className={clsx("xl:dot-border bottom bottom-only")}></div>
			)}
			{topOnly && <div className="xl:dot-border top top-only"></div>}
		</>
	);
};

export default DotBorder;
