import { ArrowRight } from "@components/Icons";
import Target from "@components/Target";
import clsx from "clsx";
import parse from "html-react-parser";

interface IProps {
	globalSet;
}

const TopBar = ({ globalSet }: IProps): JSX.Element => {
	let topBarText = null;
	if (globalSet.topBarContent) {
		topBarText = globalSet.topBarContent.replace(/<\/*p>/g, "");
	}

	const handleClick = (e, path) => {
		window.dataLayer.push({
			event: "event",
			eventProps: {
				category: "Top Bar",
				action: "Top Bar Click",
				label: path,
				value: path,
			},
		});
	};

	return (
		<div
			className={clsx("TopBar", "text-center", "text-white-01", "px-3 py-3")}
			style={{
				backgroundColor: globalSet.backgroundColor
					? globalSet.backgroundColor
					: "#6863f2",
			}}
		>
			{!!topBarText && parse(topBarText)}
			<Target
				target={globalSet.target}
				className={clsx(
					"TopBar__target",
					"inline-block",
					"border-l-2 border-color-white-01 ml-3"
				)}
			>
				<div
					className={clsx("eventContainer")}
					onClick={(e) => handleClick(e, globalSet.target.url)}
					style={{
						color: globalSet.buttonTextColor,
					}}
				>
					<span className={clsx("inline-block ml-3 font-bold ")}>
						{globalSet.target.text}
					</span>
					<div
						className={clsx(
							"TopBar__targetIcon",
							"inline-block",
							"ml-4",
							"transition-transform group-hover:translate-x-1"
						)}
					>
						<ArrowRight />
					</div>
				</div>
			</Target>
		</div>
	);
};

export default TopBar;
