import clsx from "clsx";
import { useAtom, useAtomValue } from "jotai";
import Link from "next/link";
import React, { useEffect, useState } from "react";

import {
  headerThemeAtom,
  isHeaderPinnedAtom,
  isSiteMenuActiveAtom,
  isSiteMobileMenuActiveAtom
} from "@atoms/siteHeader";
import EmbeddedForm from "@components/EmbeddedForm";
import Logotype from "@components/Logotype";
import Message from "@components/Message";
import PrimaryButton from "@components/PrimaryButton";
import { NavigationItem } from "@interfaces/NavigationItem.interface";
import MobileMenuToggle from "@modules/MobileMenuToggle";
import PrimaryNavigation from "@modules/PrimaryNavigation";
import TopBar from "@modules/TopBar";
import { AnimatePresence, motion } from "framer-motion";
import parse from "html-react-parser";
import { useRouter } from "next/router";

interface IProps {
	navigationItems: NavigationItem[];
	callToActions: NavigationItem[];
	globalSet;
}

const SiteHeader = ({
	navigationItems,
	callToActions,
	globalSet = null,
}: IProps): JSX.Element => {
	const [isHeaderPinned, setIsHeaderPinned] = useAtom(isHeaderPinnedAtom);
	const isSiteMenuActive = useAtomValue(isSiteMenuActiveAtom);
	const isSiteMobileMenuActive = useAtomValue(isSiteMobileMenuActiveAtom);
	const headerTheme = useAtomValue(headerThemeAtom);

	const [isFormActive, setIsFormActive] = useState(false);
	function toggleForm() {
		setIsFormActive(!isFormActive);
	}

	useEffect(() => {
		function onScroll() {
			const st = window.pageYOffset;
			setIsHeaderPinned(st >= 5);
		}

		window.addEventListener("scroll", onScroll);
		return () => window.removeEventListener("scroll", onScroll);
	}, [setIsHeaderPinned]);

	const [formSuccess, setFormSuccess] = useState(false);

	const handleFormSuccess = () => {
		setFormSuccess(true);
		setTimeout(() => {
			toggleForm();
		}, 3000);
	};

	const { pathname } = useRouter();

	return (
		<header
			className={clsx(
				"SiteHeader px-5",
				"sticky inset-x-0 z-50",
				"top-0",
				"w-full",
				"bg-white-01"
			)}
		>
			{!!globalSet &&
				globalSet.enableTopBar &&
				(pathname === "/" || globalSet.showOnAllPages) && (
					<TopBar globalSet={globalSet} />
				)}

			<div
				className={clsx(
					"SiteHeader__content",
					"flex items-center",
					"relative z-20",
					"max-w-screen-xl pl-7.5 md:pl-0 mx-auto",
					"transform transition-height",
					"h-20"
				)}
			>
				<Link
					href="/"
					className={clsx(
						"SiteHeader__logotype",
						"lg:ml-0 md:ml-5 h-[29px] w-[167px]",
						"hidden md:block",
						{
							"text-black-01":
								headerTheme === "dark" || isSiteMenuActive || isHeaderPinned,
							"text-white-01":
								headerTheme === "light" && !isSiteMenuActive && !isHeaderPinned,
						}
					)}
				>
					<Logotype />
				</Link>

				<Link
					href="/"
					className={clsx(
						"SiteHeader__logo",
						"w-[158px]",
						"transition-opacity",
						"block md:hidden",
						{
							block: !isSiteMobileMenuActive,
						},
						{
							"text-black-01":
								headerTheme === "dark" || isSiteMenuActive || isHeaderPinned,
							"text-white-01":
								headerTheme === "light" && !isSiteMenuActive && !isHeaderPinned,
						}
					)}
				>
					<Logotype />
				</Link>

				<div
					className={clsx(
						"SiteHeader__navigation",
						"ml-auto",
						"hidden lg:block"
					)}
				>
					<PrimaryNavigation items={navigationItems} />
				</div>

				{!!callToActions && (
					<div
						className={clsx(
							"SiteHeader__callToActions",
							"flex",
							"pl-4 xl:pr-0 xl:pl-7.5",
							"hidden lg:block"
						)}
					>
						{callToActions.map((callToAction, i) => {
							return (
								<div
									key={callToAction.id}
									onClick={toggleForm}
									className={clsx(
										"SiteHeader__callToAction inline-block cursor-pointer",
										{
											"mr-3": callToActions.length != i + 1,
										}
									)}
								>
									<PrimaryButton
										outline={
											callToAction.customAttributes.length ? true : false
										}
										target={{
											element: callToAction.element,
											type: !!callToAction.type ? "entry" : "url",
											url: callToAction.url,
											text: callToAction.title,
										}}
									/>
								</div>
							);
						})}
					</div>
				)}

				<div
					className={clsx(
						"SiteHeader__siteMobileMenuToggle",
						"block lg:hidden",
						"ml-auto",
						"relative z-50",
						{
							"text-black-01":
								headerTheme === "dark" || isSiteMenuActive || isHeaderPinned,
						}
					)}
				>
					<MobileMenuToggle />
				</div>
			</div>
			<AnimatePresence>
				{isFormActive && (
					<>
						<motion.div
							initial={{ height: 0, opacity: 0 }}
							exit={{ height: 0, opacity: 0 }}
							animate={{ height: 150, opacity: 1 }}
							className={clsx(
								"bg-white-02 py-5 text-center absolute w-full z-10"
							)}
						>
							{!formSuccess && (
                <>
                  {!!globalSet.formDescription && (
                    <div className="FormDescription relative z-10">
                      {parse(globalSet.formDescription)}
                    </div>
                  )}
									<div className="FormContainer relative max-w-screen-sm mx-auto mt-5 flex z-10">
										<EmbeddedForm
											form={globalSet.headerForm[0]}
											onSuccess={handleFormSuccess}
											inline={true}
										/>
									</div>
								</>
							)}
							<AnimatePresence>
								{formSuccess && (
									<Message message={globalSet.headerForm[0].successMessage} />
								)}
							</AnimatePresence>
						</motion.div>
						<div
							className="SiteMenu__overlay fixed inset-0 z-09"
							onClick={toggleForm}
						></div>
					</>
				)}
			</AnimatePresence>
		</header>
	);
};

export default SiteHeader;
