export const ArrowRightSmall = () => {
  return (
    <svg
      width="20"
      height="10"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.383 0l-1.109 1.092 5.231 5.149H0v1.518h18.505l-5.23 5.15L14.382 14 20 8.471V5.53L14.383 0z"
        fill="currentColor"
      />
    </svg>
  );
};

export const ArrowRight = () => {
  return (
    <svg
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.383 0l-1.109 1.092 5.231 5.149H0v1.518h18.505l-5.23 5.15L14.382 14 20 8.471V5.53L14.383 0z"
        fill="currentColor"
      />
    </svg>
  );
};

export const ArrowLeft = () => {
  return (
    <svg
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.61696 14L6.72589 12.9085L1.49464 7.75932L20 7.75932L20 6.24068L1.49464 6.24068L6.72589 1.09152L5.61696 -1.2574e-06L7.40575e-07 5.52881L6.11959e-07 7L4.83344e-07 8.47118L5.61696 14Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const ArrowDown = () => {
  return (
    <svg
      width="14"
      height="19"
      viewBox="0 0 14 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.8789 11.9883L12.8008 10.9102L7.71484 15.9961L7.71484 0.949219L6.21484 0.949219L6.21484 15.9961L1.12891 10.9102L0.0507812 11.9883L6.42578 18.3633L6.96484 18.8789L7.50391 18.3633L13.8789 11.9883Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const ArrowUp = () => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 20"
      width="14"
      height="19"
    >
      <path
        d="M-6.2857e-07 5.62L1.09 6.73L6.24 1.49L6.24 20L7.76 20L7.76 1.5L12.91 6.73L14 5.62L8.47 -3.70235e-07L5.53 -2.41724e-07L-6.2857e-07 5.62Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const CaretDown = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12 15.5455L18 9.00002H6L12 15.5455Z" fill="currentColor" />
    </svg>
  );
};

export const CaretUp = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12 8.45453L18 15H6L12 8.45453Z" fill="currentColor" />
    </svg>
  );
};

export const ChevronDown = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6 9.95057L6.93561 9.00004L11.3492 13.484L12.651 13.4839L17.0646 9.00004L18.0002 9.95057L13.2611 14.7652L12.0001 14.7652L10.7391 14.7652L6 9.95057Z" fill="currentColor" />
    </svg>
  );
};

export const ChevronUp = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M18 13.8146L17.0644 14.7652L12.6508 10.2812L11.349 10.2813L6.93542 14.7652L5.99981 13.8146L10.7389 9.00002L11.9999 9.00002L13.2609 9.00002L18 13.8146Z" fill="currentColor" />
    </svg>
  );
};

export const Search = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <circle cx="8" cy="8" r="7.25" transform="matrix(-1 0 0 1 18 2)" stroke="currentColor" stroke-width="1.5"/>
      <path d="M15.5 15.5L22 22" stroke="currentColor" stroke-width="1.5"/>
    </svg>
  );
};

export const Close = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.9999 0.999918L1.07896 14.9999"
        stroke="currentColor"
        stroke-width="1.4"
      />
      <path
        d="M0.999919 1.00008L14.9211 14.9998"
        stroke="currentColor"
        stroke-width="1.4"
      />
    </svg>
  );
};
