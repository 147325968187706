import { avoidRateLimit } from "@lib/rateLimit";
import { CraftResponse } from "@sharedInterfaces/CraftResponse.interface";
import { DocumentNode } from "graphql";
import { GraphQLClient } from "graphql-request";
import PrettyError from "pretty-error";
const pe = new PrettyError();

const craft = async (query: DocumentNode, variables = {}, token = null) => {
  await avoidRateLimit()
  // Determine the url
  let url: string = process.env.NEXT_PUBLIC_API_URL;
  if (token) url = `${url}?token=${token}`;

  const Authorization = `Bearer ${process.env.NEXT_PUBLIC_GRAPHQL_TOKEN}`;

  //const fetch = getFetch();

  const client = new GraphQLClient(url, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization,
    },
    fetch,
	});
	

  try {
		const data = await client.request(query, variables);
    return data as CraftResponse;
  } catch (error) {
    console.log(pe.render(error));

    throw error;
  }
};

export default craft;
