import { PlasmicCanvasContext } from "@plasmicapp/host";
import {
  MutableRefObject, useCallback, useContext,
  useEffect,
  useRef, useState
} from "react";

import {
  Box,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Textarea
} from "@chakra-ui/react";
import { Prose } from "@nikolovlazar/chakra-ui-prose";
import clsx from "clsx";
import parse from "html-react-parser";

import flatpickr from "flatpickr";
import { Instance } from "flatpickr/dist/types/instance";

import { FormFieldData } from "@sharedInterfaces/FormData.interface";
import { convertRegion, TO_NAME } from "@sharedLib/convertRegion";
import { FormContext } from "@sharedLib/formContext";
import { formValidators } from "@sharedLib/formValidators";
import { Field, useForm } from "react-final-form";

export const FormField = ({
  className,
  defaultValue = "",
  fieldName = "",
  fieldType = "text",
  label = "Field Label",
  layout = "column", // "row" or "column
  options = [],
  required = false,
  slimMode = false,
  toggleVisibility,
}: FormFieldData) => {
  const form = useForm();
  const {
    // Form config
    darkBackground,
    inline,
    inputColorScheme,
    showHidden,
    usePlaceholders,
    // Form values
    savedFormValues,
    formValues,
    fieldTransforms,
    countryValue,
    showState,
    urlParams,
    utmCampaign,
    // Form handlers
    handleChange = (e) => null,
    changeState = (e) => null,
  } = useContext(FormContext);

  const inEditor = useContext(PlasmicCanvasContext);
  const backgroundColor = inputColorScheme === "gray" ? "#f3f3f3" : "white";
  const rowSpacing = "16px";
  const showPlaceholder = slimMode || usePlaceholders;
  
  let isDateTime = false;  

  /* Neverbounce Status Handling */ 
  const [neverbounceStatus, setNeverbounceStatus] = useState(null);
  useEffect(() => {
    const inputElement = document.querySelector('input[name="email"]');

    const handleAttributeChange = () => {
      const newValue = inputElement?.getAttribute('data-zi-neverbounce-status');
      const prevValue = inputElement?.getAttribute('data-nbstatus');
      let updatedValue = null;
      switch (newValue) {
        case "invalid":
        case "disposable":
          updatedValue = "invalid";
          break;
        case "valid":
        case "catchall":
        case "unknown":
          updatedValue = "valid";
          break;
        default:
          updatedValue = null;
          break;
      }
      if (prevValue !== updatedValue) {
        if (process.env.NEXT_PUBLIC_LOG_LEVEL === "debug") {          
          //console.log("Neverbounce Status", neverbounceStatus);
          //console.log("Updated Value", updatedValue);
        }
        // setNeverbounceStatus(updatedValue);
        // inputElement.setAttribute('data-nbstatus', updatedValue);
        // form.mutators.setFieldData("email", { neverbounceStatus: updatedValue });
      }
    };

    // Initial attribute value
    // handleAttributeChange();

    // MutationObserver to watch for attribute changes
    const observer = new MutationObserver(handleAttributeChange);

    if (inputElement) {
      observer.observe(inputElement, {
        attributes: true,
        attributeFilter: ['data-zi-neverbounce-status'],
      });
    }

    // Cleanup observer on component unmount
    return () => {
      observer.disconnect();
    };
  }, []);

  // Check if custom transform exists for this field
  if (!!fieldTransforms[fieldName]) {
    switch (fieldName) {
      case 'state':
        // Loop through options and convert state to full name
        options.forEach((option) => {
          option.value = convertRegion(option.value, TO_NAME);
        });
        break;
    }
  }

  /* Hidden Field Handling */
  let hiddenField = false; // When true, will add hidden field display in Plasmic Editor; does not actually impact field rendering

  if (fieldName === "state") {
    if (inEditor) {
      hiddenField = true;
    } else if (!showState) {
      // Do not render field if country is not United States
      return null;
    }
  } else if (
		toggleVisibility &&
		useContext(FormContext).formValues &&
		useContext(FormContext).formValues[toggleVisibility.fieldName] !==
			toggleVisibility.targetValue
	) {
		hiddenField = true;
  }

  switch (fieldType) {
    case "text":
    case "email":
    case "telephone":
    case "number":
      if (!inEditor && hiddenField) {
        return null;
      }
      return (
        <Field
          name={fieldName}
          width={"100%"}
          initialValue={
            /* Saved Form Values */
            savedFormValues && !!savedFormValues[fieldName]
              ? savedFormValues[fieldName]
              /* URL Params w/UTM Cookie Fallback (not used for utm_campaign) */
              : urlParams && !!urlParams[fieldName]
              ? urlParams[fieldName]
              /* URL Params w/o Cookie Fallback (utm_campaign only) */
              : fieldName === "utm_campaign" && !!utmCampaign["url"]
              ? utmCampaign["url"]
              /* UTM Cookie (utm_campaign only) */
              : fieldName === "utm_campaign" && !!utmCampaign["cookie"] && !!!defaultValue
              ? utmCampaign["cookie"]
              /* Default Value */
              : defaultValue
          }
          validate={fieldType === "email" ? formValidators : null}
        >
          {({ input, meta }) => (
            <FormControl
              className={clsx(className, {
                grow: inline
              })}
              data-name={fieldName}
              isRequired={required}
              isInvalid={meta.error && meta.touched}
              mb={rowSpacing}
              backgroundColor={backgroundColor}
            >
              <Box
                borderColor={meta.error && meta.touched ? "#CB0A0A" : backgroundColor}
                borderWidth="1px"
              >
                {!showPlaceholder && !!label && <FormLabel backgroundColor={backgroundColor}>{parse(label)}</FormLabel>}
                <Input
                  type={fieldType === "telephone" ? "tel" : fieldType}
                  data-nbstatus={ meta.data.neverbounceStatus }
                  {...input}
                  variant={showPlaceholder ? "slim" : "default"}
                  placeholder={
                    showPlaceholder && !!label
                    ? parse(label) as string
                    : (hiddenField && inEditor 
                        ? `Hidden by: ${toggleVisibility.fieldName}`
                        : null
                      )
                  }
                  onChange={(e) => {
                    handleChange(e);
                    input.onChange(e);
                  }}
                  backgroundColor={backgroundColor}
                />
                <FormErrorMessage>{meta.error}</FormErrorMessage>
              </Box>
            </FormControl>

          )}
        </Field>
      );
    case "select":
      return (
        <Field
          name={fieldName}
          initialValue={
            hiddenField && inEditor
              ? "hidden"
              : fieldName === "country" && countryValue
              ? countryValue
              : savedFormValues && !!savedFormValues[fieldName]
              ? savedFormValues[fieldName]
              : urlParams && !!urlParams[fieldName]
              ? urlParams[fieldName]
              : defaultValue
          }
        >
          {({ input, meta }) => (
            <FormControl
              className={clsx(className, {
                grow: inline
              })}
              data-name={fieldName}
              isRequired={required}
              mb={rowSpacing}
              backgroundColor={backgroundColor}
            >
              {!showPlaceholder && !!label && <FormLabel backgroundColor={backgroundColor}>{parse(label)}</FormLabel>}
              {fieldName === "country" && (
                <Select
                  {...input}
                  onChange={(e) => {
                    changeState(e.target.value);
                    handleChange(e);
                    input.onChange(e);
                  }}
                  backgroundColor={backgroundColor}
                >
                  {options.length &&
                    options.map((option, index) => {
                      return (
                        <option
                          key={`${fieldName}-${index}`}
                          value={option?.value ? option.value : ""}
                        >
                          {option.label}
                        </option>
                      );
                    })}
                </Select>
              )}
              {fieldName !== "country" && (
                <Select
                  {...input}
                  onChange={(e) => {
                    handleChange(e);
                    input.onChange(e);
                  }}
                  backgroundColor={backgroundColor}
                >
                  {hiddenField && inEditor && fieldName === "state" ? (
                    <option value="hidden">Hidden by Country Field</option>
                  ) : hiddenField && inEditor ? (
                    <option value="hidden">
                      Hidden by: {toggleVisibility.fieldName}
                    </option>
                  ) : null}
                  {options.length &&
                    options.map((option, index) => {
                      return (
                        <option
                          key={`${fieldName}-${index}`}
                          value={option?.value ? option.value : ""}
                        >
                          {option.label}
                        </option>
                      );
                    })}
                </Select>
              )}
            </FormControl>
          )}
        </Field>
      );
    case "radioButtons":
      return (
        <Field
          name={fieldName}
          type="radio"
          initialValue={
            savedFormValues && !!savedFormValues[fieldName]
              ? savedFormValues[fieldName]
              : urlParams && !!urlParams[fieldName]
              ? urlParams[fieldName]
              : defaultValue
          }
        >
          {({ input, meta }) => (
            <FormControl
              className={clsx(className, {
                grow: inline
              })}
              data-name={fieldName}
              isRequired={required}
              mb={rowSpacing}
            >
              {!!label && (<FormLabel backgroundColor={backgroundColor}>{parse(label)}</FormLabel>)}
              <RadioGroup
                {...input}
                defaultValue={
                  savedFormValues && !!savedFormValues[fieldName]
                    ? savedFormValues[fieldName]
                    : urlParams && !!urlParams[fieldName]
                    ? urlParams[fieldName]
                    : defaultValue
                }
                variant={darkBackground ? "darkBackground" : "default"}
                colorScheme={darkBackground ? "white" : "purple"}
                onChange={(value) => {
                  const radioEvent = {
                    target: {
                      name: fieldName,
                      value: value,
                      type: "radio",
                      checked: false
                    }
                  };
                  handleChange(radioEvent);
                  input.onChange(radioEvent);
                }}
              >
                <Stack
                  direction={layout}
                  spacing={layout === "row" ? 4 : 2}
                  backgroundColor={!!label && backgroundColor}
                  p={!!label && "8px 16px"}
                >
                  {options.length &&
                    options.map((option, index) => {
                      return (
                        <Radio
                          key={`${fieldName}-${index}`}
                          value={option.value}
                        >
                          {option.label}
                        </Radio>
                      );
                    })}
                </Stack>
              </RadioGroup>
            </FormControl>
          )}
        </Field>
      );
    case "checkbox":
      return (
        <Field
          name={fieldName}
          type="checkbox"
          initialValue={
            savedFormValues && !!savedFormValues[fieldName]
              ? savedFormValues[fieldName]
              : urlParams && !!urlParams[fieldName]
              ? urlParams[fieldName]
              : defaultValue
          }
        >
          {({ input, meta }) => (
            <FormControl
              className={clsx(className, {
                grow: inline
              })}
              data-name={fieldName}
              isRequired={required}
              mb={rowSpacing}
            >
              <Checkbox
                name={fieldName}
                defaultChecked={defaultValue[0] === undefined ? false : defaultValue[0].toLowerCase() === "y"}
                {...input}
                variant={darkBackground ? "darkBackground" : "default"}
                colorScheme={darkBackground ? "white" : "purple"}
                spacing="1rem"
                onChange={(e) => {
                  handleChange(e);
                  input.onChange(e);
                }}
              >
                {!!label && (<Prose>{parse(label)}</Prose>)}
              </Checkbox>
            </FormControl>
          )}
        </Field>
      );
    case "textArea":
      return (
        <Field
          name={fieldName}
          backgroundColor={backgroundColor}
          initialValue={
            savedFormValues && !!savedFormValues[fieldName]
              ? savedFormValues[fieldName]
              : urlParams && !!urlParams[fieldName]
              ? urlParams[fieldName]
              : defaultValue
          }
        >
          {({ input, meta }) => (
            <FormControl
              className={clsx(className, {
                grow: inline
              })}
              data-name={fieldName}
              isRequired={required}
              mb={rowSpacing}
              backgroundColor={backgroundColor}
            >
              {!showPlaceholder && !!label && <FormLabel backgroundColor={backgroundColor}>{parse(label)}</FormLabel>}
              <Textarea
                name={fieldName}
                {...input}
                backgroundColor={backgroundColor}
                placeholder={
                  showPlaceholder && !!label
                  ? parse(label) as string
                  : (hiddenField && inEditor 
                      ? `Hidden by: ${toggleVisibility.fieldName}`
                      : null
                    )
                }
                onChange={(e) => {
                  handleChange(e);
                  input.onChange(e);
                }}
              />
            </FormControl>
          )}
        </Field>
      );
    case "hidden":
      return (
        <>
          {!inEditor && (
            <Field
              name={fieldName}
              initialValue={
                /* Saved Form Values */
                savedFormValues && !!savedFormValues[fieldName]
                  ? savedFormValues[fieldName]
                  /* URL Params w/UTM Cookie Fallback (not used for utm_campaign) */
                  : urlParams && !!urlParams[fieldName]
                  ? urlParams[fieldName]
                  /* URL Params w/o Cookie Fallback (utm_campaign only) */
                  : fieldName === "utm_campaign" && !!utmCampaign["url"]
                  ? utmCampaign["url"]
                  /* UTM Cookie (utm_campaign only) */
                  : fieldName === "utm_campaign" && !!utmCampaign["cookie"] && !!!defaultValue
                  ? utmCampaign["cookie"]
                  /* Default Value */
                  : defaultValue
              }
            >
              {({ input, meta }) => (
                <FormControl
                  className={className}
                  isRequired={required}
                  display="none"
                  data-name={fieldName}
                >
                  <Input
                    name={fieldName}
                    type="hidden"
                    {...input}
                    onChange={(e) => {
                      handleChange(e);
                      input.onChange(e);
                    }}
                  />
                </FormControl>
              )}
            </Field>
          )}
          {inEditor && showHidden && <Box>Hidden Field: {fieldName}</Box>}
        </>
      );
    case "htmltext":
      return !!defaultValue && (
        <Box
          className={className}
          mb={rowSpacing}
        >
          <Prose>{parse(defaultValue)}</Prose>{/* TODO: Remove nested parse */}
        </Box>
      );
    default:
      return (
        <Box>
          {fieldType}
        </Box>
      );
    case "dateTime":
    case "datetime":
    case "datetime-local":
      isDateTime = true;
    case "date":
      const datePicker = useRef() as MutableRefObject<Instance>;
      return (
        <Field
          name={fieldName}
          width={"100%"}
          initialValue={
            savedFormValues && !!savedFormValues[fieldName]
              ? savedFormValues[fieldName]
              : urlParams && !!urlParams[fieldName]
              ? urlParams[fieldName]
              : defaultValue
          }
        >
          {({ input, meta }) => {
            const inputRef = useCallback((node) => {
              if (node !== null) {
                datePicker.current = flatpickr(node, {
                  //flatpickr options, for full list see - https://flatpickr.js.org/options/
                  enableTime: isDateTime,
                  minDate: "today",
                  altInput: true,
                  allowInput: false,
                  altInputClass: `fpAltInput ${backgroundColor === '#f3f3f3' as string ? 'gray-bg' : ''}`,
                  altFormat:
                    // user friendly format to display
                    isDateTime ? "F j, Y at h:i K" : "F j, Y",
                  dateFormat:
                    // format of data that is actually sent
                    //fieldType === "dateTime" ? "Y-m-dTH:i:S" : "Y-m-d",
                    isDateTime ? "Y-m-dTH:i:S" : "Y-m-d",
                  onChange: (selectedDates, dateStr, instance) => {
                    input.onChange(dateStr);
                  },
                });
              }
            }, []);

            return (
              <FormControl
                className={clsx(className, {
                  grow: inline
                })}
                isRequired={required}
                mb={rowSpacing}
                backgroundColor={backgroundColor}
              >
                <Box
                  borderColor={meta.error && meta.touched ? "#CB0A0A" : backgroundColor}
                  borderWidth="1px"
                >
                  {!showPlaceholder && !!label && <FormLabel backgroundColor={backgroundColor}>{parse(label)}</FormLabel>}
                  <Input
                    name={fieldName}
                    value={input.value}
                    type="date"
                    ref={inputRef}
                    variant={showPlaceholder ? "slim" : "default"}
                    placeholder={
                      showPlaceholder && !!label
                      ? parse(label) as string
                      : (hiddenField && inEditor 
                          ? `Hidden by: ${toggleVisibility.fieldName}`
                          : null
                        )
                    }
                    onChange={(e) => {
                      //console.log(e);
                    }}
                    backgroundColor={backgroundColor}
                  />
                  <FormErrorMessage>{meta.error}</FormErrorMessage>
                </Box>
              </FormControl>
            );
          }}
        </Field>
      );
  }
};

export default FormField;
