import clsx from "clsx";
import Link from "next/link";
import { useRouter } from "next/router";

import { getHref } from "@lib/routes";

const FooterPrimaryNavigation = ({ items }) => {
	const parentItems = items.filter((i) => i.level === 1);

	const width = 100 / parentItems.length;

	const Item = ({ item, isParent = false }) => {
		const router = useRouter();

		const href = item.element ? getHref(item.element) : item.url;

		const target = !item.element ? "_blank" : null;

		const isActive = href === router.asPath;

		if (!href) return null;

		const childItems = items.filter((i) => i.parent?.id === item.id);

		return <>
            <div
                className={clsx(
                    "FooterPrimaryNavigation__item",
                    "block",
                    "py-2",
                    "text-white-03",
                    {
                        "!w-1/2 md:!w-full lg:w-auto": isParent,
                        "md:w-1/2 lg:w-auto": !isParent,
                        "pr-3.5 md:mb-5": isParent,
                        "font-medium text-xl md:text-2xl": isParent,
                    }
                )}
                style={{ width: isParent ? `${100 / parentItems.length}%` : null }}
            >
                <Link
                    href={href}
                    prefetch={false}
                    target={target}
                    className={clsx(
                        "FooterPrimaryNavigation__itemLink",
                        "hover-underline",
                        "relative",
                        "block",

                        {
                            "font-medium whitespace-nowrap": isParent,
                            "text-sm md:text-base": !isParent,
                            "mb-4 lg:mb-7.5": isParent,
                        }
                    )}>

                    <span className={clsx("py-1")}>{item.title}</span>

                </Link>
                {!!childItems && (
                    <div
                        className={clsx(
                            "FooterPrimaryNavigation__childItems",
                            "md:flex lg:block flex-wrap"
                        )}
                    >
                        {childItems.map((childItem) => {
                            return <Item key={childItem.id} item={childItem} />;
                        })}
                    </div>
                )}
            </div>
        </>;
	};

	return (
		<div className={clsx("FooterPrimaryNavigation")}>
			<div
				className={clsx(
					"FooterPrimaryNavigation__items",
					"flex flex-row flex-wrap lg:flex-nowrap",
					"w-full"
				)}
			>
				{parentItems.map((item) => {
					return <Item key={item.id} item={item} isParent />;
				})}
			</div>
		</div>
	);
};

export default FooterPrimaryNavigation;
