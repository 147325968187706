import clsx from "clsx";
import { useAtom } from "jotai";

import { isSiteMobileMenuActiveAtom } from "@atoms/siteHeader";

const MobileMenuToggle = () => {
	const [isSiteMobileMenuActive, setIsSiteMobileMenuActive] = useAtom(
		isSiteMobileMenuActiveAtom
	);

	return (
		<button
			onClick={() => setIsSiteMobileMenuActive((old) => !old)}
			className={clsx(
				"MobileMenuToggle",
				"w-20 md:w-40 lg:w-20 h-20 md:h-24 lg:h-20",
				"flex items-center justify-center"
			)}
		>
			{!isSiteMobileMenuActive && (
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<rect y="7" width="24" height="2" fill="currentColor" />
					<rect y="15" width="24" height="2" fill="currentColor" />
				</svg>
			)}
			{!!isSiteMobileMenuActive && (
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<rect
						x="3"
						y="20"
						width="24"
						height="2"
						transform="rotate(-45 3 20)"
						fill="#000"
					/>
					<rect
						x="4"
						y="3"
						width="24"
						height="2"
						transform="rotate(45 4 3)"
						fill="#000"
					/>
				</svg>
			)}
		</button>
	);
};

export default MobileMenuToggle;
