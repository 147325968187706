import { getHref } from "lib/routes";
import Link from "next/link";

const Target = ({
	className = "",
	target,
	children,
	baseUrl = "",
	style = {},
	onMouseEnter = () => null,
	onMouseLeave = () => null,
}): JSX.Element => {

	const returnElement = () => {
		return (
			<div className={className} style={style}>
				{children}
			</div>
		);
	};

	if (!target || target.type === "") {
		return returnElement();
	}

	if (target.type === "entry" && !!target.element) {
		const href = getHref(target.element, baseUrl);

		if (!href) return returnElement();

		return (
            (<Link
                href={href}
                className={className}
                style={style}
                draggable={false}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}>

                {children}

            </Link>)
        );
	}

	if (target.type === "asset" && !!target.element) {
		return (
			<a
				href={target.url}
				className={className}
				draggable={false}
				download
				target="_blank"
				rel="noreferrer"
				style={style}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
			>
				{children}
			</a>
		);
	}

	if (target.type === "url") {
		return (
			<a
				href={target.url}
				className={className}
				target="_blank"
				rel="noreferrer"
				draggable={false}
				style={style}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
			>
				{children}
			</a>
		);
	}

	if (target.type === "email") {
		return (
			<a
				href={target.url}
				className={className}
				style={style}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
			>
				{children}
			</a>
		);
	}

	if (target.type === "custom") {
		return (
			<a
				href={target.url}
				className={className}
				draggable={false}
				style={style}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
			>
				{children}
			</a>
		);
	}

	return returnElement();
};

export default Target;
