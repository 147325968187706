import { Box } from "@chakra-ui/react";
import { ArrowRight } from "@components/Icons";
import { fetchForm } from "@lib/fetchForm";
import { Form } from "@sharedComponents/Form";
import { FormDataCraft } from "@sharedInterfaces/FormData.interface";
import clsx from "clsx";
import { useState } from "react";

/* Fetch form data async */
const getForm = async (formId) => {
  const formData = await fetchForm({ formId: formId });
  return await formData;
};

/* Format form field data for the Form component */
const formatFieldData = (formData, fieldValues) => {
  return formData.map(field => ({
    ...field,
    fieldName: field.remoteIdRest,
    fieldType: field.dataType,
    label: field.label.replace(":", ""),
    defaultValue: !!fieldValues[field.remoteIdRest] ? fieldValues[field.remoteIdRest] : field.defaultValue[0],
  }));
}

export const EmbeddedForm = ({
  buttonBackgroundColor = null,
  buttonBackgroundColorHover = null,
  buttonTextColor = null,
  buttonTextColorHover = null,
  className,
  darkBackground = false,
  form,
  formData = null,
  formValues = null,
  inline = false,
  inputColorScheme,
  overrideButtonText = null,
  usePlaceholders = false,
  // darkMode = false,
  // outlineButton = false,
  // transparentInputs = false,
  onChange = (e) => null,
  onSuccess = () => null,
}: FormDataCraft) => {

  /* Populate formDetails, exiting if formDetails is empty */
  let formDetails;
  if (formValues) {
    /* Populate directly from passed formValues */
    formDetails = formValues;
  } else {
    /* Pull formDetails from the form object */
    formDetails = form.formDetails[0];
  }
  if (!formDetails) return null;
  const marketoFormId = formDetails?.formId;

  /* Form field data */
  const [formFields, setFormFields] = useState(formData ? formatFieldData(formData, formDetails) : null); /* Form fields for the current form */
  if (!formFields && marketoFormId) {
    getForm(marketoFormId).then((data) => setFormFields(formatFieldData(data, formDetails)));
  }

  /* Submit button */
  const buttonText = (
    <>
      {overrideButtonText ? overrideButtonText : form?.buttonText}
      <Box
        className={clsx(
          "EmbeddedForm__buttonIcon",
          "ml-4",
          "transition-transform group-hover:translate-x-1"
        )}
      >
        <ArrowRight />
      </Box>
    </>
  )

  return (
    <Box
      className={clsx("EmbeddedForm", {
        "max-w-2xl": !inline,
        "w-full": inline,
      })}
    >
      <Form
        className={className}
        darkBackground={darkBackground}
        formFields={formFields}
        formName={form?.title ? form.title : "Unknown Form"}
        inline={inline}
        inputColorScheme={inputColorScheme}
        isPlasmic={false}
        marketoFormId={marketoFormId}
        showHidden={false}
        submitButton={{
          text: buttonText,
          backgroundColor: buttonBackgroundColor,
          backgroundColorHover: buttonBackgroundColorHover,
          textColor: buttonTextColor,
          textColorHover: buttonTextColorHover,
        }}
        usePlaceholders={usePlaceholders}
        onChange={onChange}
        onSuccess={onSuccess}
      />
    </Box>
  );
};

export default EmbeddedForm;
