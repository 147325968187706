export function getHref(element: {
  groupHandle?: string;
  sectionHandle?: string;
  slug?: string;
	uri?: string;
	baseUrl?: string;
}, baseUrl?: string): string {
  if (!element) return `/`;

	const handle = element.sectionHandle || element.groupHandle;

  switch (handle) {
    case "pressIndex":
      return `/about/news`;
    case "press":
      return `/about/news/${element.slug}`;

    case "newsTypes":
      return `/about/news/category/${element.slug}`;

    case "blogIndex":
      return `/resources`;
    case "blog":
      return `/blog/${element.slug}`;

    case "glossaryIndex":
      return `/glossary`;
    case "glossary":
      return `/glossary/${element.slug}`;

    case "blogCategories":
      return `/resources/category/blog`;
    case "postAuthors":
      return `/blog/author/${element.slug}`;

    case "releaseNoteIndexOld":
      return `/release-notes`;
    case "releaseNotesOld":
      return `/release-notes/${element.slug}`;

    case "resourceIndex":
      return `/resources`;
    case "resources":
			return `/resources/${element.slug}`;
		
		case "attackVaultIndex":
      return `/attack-vault`;
    case "attackVault":
			return `/attack-vault/${element.slug}`;
		
		case "attackLibraryIndex":
      return `/attack-library`;
    case "attackLibrary":
			return `/attack-library/${element.slug}`;
		
		case "attackVectors":
			return `${baseUrl ? baseUrl : "/attack-vault"}/category/attack-vectors/${element.slug}`;
		case "attackGoals":
			return `${baseUrl ? baseUrl : "/attack-vault"}/category/attack-goals/${element.slug}`;
		case "attackTactics":
			return `${baseUrl ? baseUrl : "/attack-vault"}/category/attack-tactics/${element.slug}`;
		case "attackImpersonatedParties":
			return `${baseUrl ? baseUrl : "/attack-vault"}/category/impersonated-parties/${element.slug}`;
		case "attackTypes":
			return `${baseUrl ? baseUrl : "/attack-vault"}/category/attack-types/${element.slug}`;
		case "attackThemes":
			return `${baseUrl ? baseUrl : "/attack-vault"}/category/attack-themes/${element.slug}`;
		case "attackImpersonatedBrands":
			return `${baseUrl ? baseUrl : "/attack-vault"}/category/impersonated-brands/${element.slug}`;
		case "attackLanguage":
			return `${baseUrl ? baseUrl : "/attack-vault"}/category/attack-language/${element.slug}`;
		case "attackAttachmentType":
			return `${baseUrl ? baseUrl : "/attack-vault"}/category/attachment-type/${element.slug}`;
		case "attackAiGenerated":
			return `${baseUrl ? baseUrl : "/attack-vault"}/category/ai-generated/${element.slug}`;

    case "resourceTypes":
      return `/resources/category/${element.slug}`;
    case "topics":
      return `/resources/category/${element.slug}`;
    case "industries":
      return `/resources/category/${element.slug}`;

    case "eventIndex":
      return `/about/events`;
    case "events":
      return `/about/events/${element.slug}`;

    case "eventTypes":
      return `/about/events/category/${element.slug}`;


    case "contact":
      return `/contact`;

    case "pages":
      return `/${element.uri}`;

    case "demo":
      return `/demo`;

    default:
      return `/`;
  }
}

export default getHref;
